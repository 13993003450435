import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.filter.js";
// CSS
import '../styles/main.scss';

// Libraries
//? Jquery
import $ from 'jquery';

//? Bootstrap
import 'bootstrap';

//? Jquery
import 'jquery-ui/dist/jquery-ui.min.js';
import 'jquery-validation/dist/jquery.validate';
import 'jquery.scrollbar/jquery.scrollbar';
import 'jquery-listnav/jquery-listnav';
import './vendors/jquery.scrollslider'; //todo Broken looks like it isnt even used.

//? Other
import 'owl.carousel';
import 'nouislider/dist/nouislider.js';
window.jQuery = $;
window.$ = $;
var requireContext = [require.context('../images', true, /\.(ico|jpg|jpeg|png|gif|webp|svg)$/i), require.context('../fonts', true, /\.(eot|svg|ttf|woff|woff2|otf)$/i)];

//? Initialize images and fonts. This is needed so that webpack builds the folders.
requireContext.forEach(function (context) {
  context.keys().forEach(function (key) {
    context(key);
  });
});
$(function () {
  var app = {
    render: function render() {
      var page = $('.page').data('id');
      switch (page) {
        case 'home':
          import('../apps/home/home');
          break;
        case 'about_us':
          import('../apps/about_us/about_us');
          break;
        case 'audiancares':
          import('../apps/audiancares/audiancares');
          break;
        case 'device':
        case 'services':
          import('../apps/services/services');
          break;
        case 'contact':
          import('../apps/contact/contact');
          break;
        case 'support':
          import('../apps/support/support');
          break;
        case 'blog':
          import('../apps/blog_page/blog_page');
          break;
        default:
          break;
      }
      this.navbarEvents(); // Activate Nav Bar Events.
      this.globalBindEvents(); // Bind Events used in multiple apps.
    },
    navbarEvents: function navbarEvents() {
      $('.navbar-toggler').click(function () {
        $('.menu-layer').toggleClass('in');
      });
      $('.close-btn').click(function () {
        $('.menu-layer').toggleClass('in');
        $('#navbarNav').collapse('hide');
      });

      //? Main Navbar Fix - Forces a reload of the page when on the solutions page and trying to navigate to another solutions page.
      //? This was a pre-existing issue on the old build where changing hashes from the main navbar wont trigger bootstraps tab content.
      $('.force-load').on('click', function (e) {
        var targetPath = $(e.currentTarget).attr('href');
        var targetHash = targetPath.slice(targetPath.indexOf('#'));
        window.location.hash = targetHash;
        window.location.reload();
      });
    },
    globalBindEvents: function globalBindEvents() {
      $('[data-toggle="tooltip"]').tooltip();

      // //TODO - Cleanup
      // $('.list-inline a').click(function () {
      //     const text = $(this).attr('tab');

      //     localStorage.setItem('menu', text);
      // });

      $('.sec-side-bar a').on('click', function (e) {
        e.preventDefault();
        var value = $(this).text().toLowerCase();
        $('.list-inline li').filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    }
  };
  app.render();
});